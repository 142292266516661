export var SearchList = [
  { labe: '需求单号', code: 'demandOrderCode', type: 'input' },
  { labe: '物料编号', code: 'materialCode', type: 'input' },
  { labe: '物料描述', code: 'materialDesc', type: 'input' },
  {
    labe: '送货基地',
    code: 'baseCode',
    type: 'select',
    option: []
  },
  {
    labe: '下发时间',
    code: 'rkTime',
    type: 'daterange'
  },
  { labe: '采购员', code: 'buyer', type: 'input', placeholder: '采购员编码或名称' }
]
export var SearchData = {
  demandOrderCode: '',
  materialCode: '',
  materialDesc: '',
  baseCode: '',
  rkTime: ''
}
export var checkEd = { data: [], dataLable: '' }
export var tableField1 = {
  type: 'input',
  reserveSelection: true,
  selectType: 'selection',
  width: '50',
  selectable: function (data) {
    if (checkEd.dataLable === '待响应') {
      return checkEd.data.length <= 0 ? true : checkEd.data[0].baseName === data.baseName && checkEd.data[0].providerName === data.providerName
    } else {
      return true
    }
  }
}
export var tableField = [
  { label: '需求单号', code: 'demandCode', type: 'input', width: '160' },
  { label: '需求单行号', code: 'demandRow', type: 'input', width: '110' },
  { label: '物料编号', code: 'materialCode', type: 'input', width: '110' },
  { label: '物料描述', code: 'materialName', type: 'input', width: '230' },
  { label: '需求数量', code: 'demandNumber', type: 'input', width: '100' },
  { label: '待响应数量', code: 'remainNumber', type: 'input', width: '110' },
  { label: '需求送货基地', code: 'baseName', type: 'input', width: '130' },
  { label: '下发日期', code: 'issueTime', type: 'input', width: '130' },
  {
    label: '需求类型',
    code: 'demandType',
    type: 'function',
    width: '90',
    handle: (index, data) => {
      return data.demandType === 1
        ? '集采分发'
        : data.demandType === 2
          ? '兜底入库'
          : data.demandType === 3
            ? '战略入库'
            : data.demandType === 4
              ? '备货入库'
              : data.demandType === 5 ? '分发换料' : data.demandType === 6 ? '辅料入库' : data.demandType === 7 ? '不良补货' : data.demandType === 8 ? '计划到货' : data.demandType === 9 ? '系统JIT补货' : data.demandType === 10 ? '泓泰直送昆山' : data.demandType === 11 ? '预配送货' : data.demandType
    }
  }
]

export var tableField2 = [
  { label: '需求单号', code: 'demandCode', type: 'input', width: '160' },
  { label: '需求单行号', code: 'demandRow', type: 'input', width: '110' },
  { label: '物料编号', code: 'materialCode', type: 'input', width: '110' },
  { label: '物料描述', code: 'materialName', type: 'input', width: '230' },
  { label: '原供应商名称', code: 'providerName', type: 'input', width: '230' },
  { label: '需求数量', code: 'demandNumber', type: 'input', width: '100' },
  { label: '待响应数量', code: 'remainNumber', type: 'input', width: '110' },
  { label: '需求送货基地', code: 'baseName', type: 'input', width: '130' },
  { label: '下发日期', code: 'issueTime', type: 'input', width: '130' },
  { label: '需求到货日期', code: 'xqdhrq', type: 'input', width: '110' },
  {
    label: '需求类型',
    code: 'demandType',
    type: 'function',
    width: '90',
    handle: (index, data) => {
      return data.demandType === 1
        ? '集采分发'
        : data.demandType === 2
          ? '兜底入库'
          : data.demandType === 3
            ? '战略入库'
            : data.demandType === 4
              ? '备货入库'
              : data.demandType === 5 ? '分发换料' : data.demandType === 6 ? '辅料入库' : data.demandType === 7 ? '不良补货' : data.demandType === 8 ? '计划到货' : data.demandType === 9 ? '系统JIT补货' : data.demandType === 10 ? '泓泰直送昆山' : data.demandType === 11 ? '预配送货' : data.demandType
    }
  }
]

export var tableData = []

export var bidSupplierList = []
